import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import UserManagement from "./pages/UserManagement";
import TransactionManagement from "./pages/TransactionManagement";
import Context from "./pages/Context";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Withdrawal from "./pages/Withdrawal";
import Bets from "./pages/Bets";
import Market from "./pages/Market";
import BankDetailsApproval from "./pages/BankDetailsApproval";
import AdminPaymentSettings from "./pages/AdminPaymentSettings";
import SettledBets from "./pages/SettledBets";
import PendingBets from "./pages/PendingBets";
import MarketHistory from "./pages/MarketHistory";
import GetApp from "./pages/GetApp";
import Slabs from "./pages/Slabs";

const ProtectedRoute = ({ children }) => {
  const auth = localStorage.getItem("adminAuth");
  const location = useLocation();

  if (!auth) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

const Layout = ({ children }) => {
  const location = useLocation();
  const showHeaderAndSidebar =
    location.pathname !== "/" && location.pathname !== "/admin-panel-login";

  return (
    <div className="flex flex-col h-screen">
      {showHeaderAndSidebar && <Header />}
      {showHeaderAndSidebar && <Sidebar />}
        <main className="flex-grow p-6 bg-gray-100 overflow-auto">
          {children}
        </main>
      
      
    </div>
  );
};

function App() {
  return (
    <Context>
      <Router>
        <Routes>
          <Route path="/" element={<GetApp />} />

          <Route path="/admin-panel-login" element={<Login />} />


          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Layout>
                  <Dashboard />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/AdminPaymentSettings"
            element={
              <ProtectedRoute>
                <Layout>
                  <AdminPaymentSettings /> {/* This is correct */}
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/SettledBets"
            element={
              <ProtectedRoute>
                <Layout>
                  <SettledBets /> {/* This is correct */}
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/PendingBets"
            element={
              <ProtectedRoute>
                <Layout>
                  <PendingBets /> {/* This is correct */}
                </Layout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/users"
            element={
              <ProtectedRoute>
                <Layout>
                  <UserManagement />
                </Layout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/withdrawal"
            element={
              <ProtectedRoute>
                <Layout>
                  <Withdrawal />
                </Layout>
              </ProtectedRoute>
            }
          />

         

          <Route
            path="/transactions"
            element={
              <ProtectedRoute>
                <Layout>
                  <TransactionManagement />
                </Layout>
              </ProtectedRoute>
            }
          />



          <Route
            path="/market"
            element={
              <ProtectedRoute>
                <Layout>
                  <Market />
                </Layout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/bank-details"
            element={
              <ProtectedRoute>
                <Layout>
                  <BankDetailsApproval />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/MarketHistory"
            element={
              <ProtectedRoute>
                <Layout>
                  <MarketHistory />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/Slabs"
            element={
              <ProtectedRoute>
                <Layout>
                  <Slabs />
                </Layout>
              </ProtectedRoute>
            }
          />

          {/* Catch all route - redirect to dashboard if authenticated, login if not */}
          <Route
            path="*"
            element={
              localStorage.getItem("adminAuth") ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
        </Routes>
      </Router>
    </Context>
  );
}

export default App;
