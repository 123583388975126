import axios from "axios";
import React, { useState, useEffect } from "react";
import { FaBell, FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [lastNotificationCount, setLastNotificationCount] = useState(0);
  const notificationSound = new Audio("./notification.mp3");
  const navigate = useNavigate();

  const fetchNotifications = async () => {
    try {
      const response = await axios.get("https://sratebackend-1.onrender.com/users/user");

      const allNotifications = response.data.flatMap((user) => {
        const transactionRequests = (user.transactionRequest || []).map((transaction) => ({
          type: "Add Fund",
          amount: transaction.amount,
          username: user.username,
          time: new Date(transaction.requestTime).toLocaleString(),
          status: transaction.status,
        }));

        const withdrawalRequests = (user.withdrawalRequest || []).map((withdrawal) => ({
          type: "Withdrawal",
          amount: withdrawal.amount,
          username: user.username,
          time: new Date(withdrawal.requestTime).toLocaleString(),
          status: withdrawal.status,
        }));

        return [...transactionRequests, ...withdrawalRequests];
      });

      if (allNotifications.length > lastNotificationCount) {
        setLastNotificationCount(allNotifications.length);
        setIsModalOpen(true);
        playNotificationSound();
      }

      setNotifications(allNotifications.reverse());
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const playNotificationSound = () => {
    notificationSound.play().catch((error) => {
      console.error("Error playing notification sound:", error);
    });
  };

  useEffect(() => {
    fetchNotifications();
    const interval = setInterval(fetchNotifications, 10000);
    return () => clearInterval(interval);
  }, [lastNotificationCount]);

  const handleNotificationClick = (type) => {
    if (type === "Add Fund") {
      navigate("/TransactionManagement");
    } else {
      navigate("/Withdrawal");
    }
    setIsModalOpen(false); // Close modal after navigation
  };

  return (
    <header className="flex justify-between items-center bg-gray-900 text-white p-4 shadow-lg">
      <div className="flex items-center">
        <h1 className="text-2xl font-bold">Admin Panel</h1>
      </div>
      <div className="flex items-center space-x-6">
        <FaBell
          className="text-xl cursor-pointer hover:text-yellow-400"
          onClick={() => setIsModalOpen(!isModalOpen)}
        />
        <FaUserCircle className="text-xl cursor-pointer hover:text-yellow-400" />
      </div>

      {/* Notification Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[100]">
          <div className="bg-gray-800 rounded-lg shadow-lg p-6 w-[90%] md:w-[50%] h-[80%] flex flex-col">
            <div className="flex justify-between items-center mb-4 text-white">
              <h2 className="text-2xl font-bold">Latest Notifications</h2>
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-red-500 text-2xl hover:text-red-600"
              >
                ✕
              </button>
            </div>
            <div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-900">
              {notifications.filter((n) => n.status === "Pending").length === 0 ? (
                <p className="text-center text-gray-400 mt-10">No new notifications</p>
              ) : (
                <ul className="space-y-4">
                  {notifications.filter((n) => n.status === "Pending").map((notification, index) => (
                    <li
                      onClick={() => handleNotificationClick(notification.type)}
                      key={index}
                      className={`p-4 rounded-lg shadow-md transform transition-transform duration-500 hover:scale-95 bg-black text-white animate-pulse cursor-pointer ${
                        notification.type === "Add Fund"
                          ? "border-l-4 border-green-500"
                          : "border-l-4 border-red-500"
                      }`}
                    >
                      <div className="flex justify-between items-center mb-2">
                        <p className="text-lg font-medium">
                          <strong>{notification.username}</strong>
                        </p>
                        <span
                          className={`px-3 py-1 text-sm rounded-full ${
                            notification.type === "Add Fund"
                              ? "bg-green-600 text-white"
                              : "bg-red-600 text-white"
                          }`}
                        >
                          {notification.type}
                        </span>
                      </div>
                      <p>
                        <strong>Amount:</strong> ₹{notification.amount}
                      </p>
                      <p>
                        <strong>Status:</strong> {notification.status}
                      </p>
                      <p className="text-sm text-gray-400">
                        <strong>Time:</strong> {notification.time}
                      </p>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="text-center mt-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
