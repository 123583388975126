import React, { useState, useEffect } from "react";
import axios from "axios";

const TransactionVerification = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    username: "",
    phone: "",
  });
  const [allUserData, setAllUserData] = useState([]);
  
  //Filter Function
  const applyFilters = () => {
    return transactions
    .filter((transaction) => {
      const matchesUsername = transaction?.username
        .toLowerCase()
        .includes(filters.username?.toLowerCase());
      const matchesPhone = transaction.phone?.toLowerCase()
        .includes(filters.phone?.toLowerCase());
      return matchesUsername && matchesPhone;
    });
  };
  const filteredTransactions = applyFilters();
  
  // Fetch transactions from backend
  const fetchTransactions = async () => {
    try {
      const response = await axios.get(
        `https://sratebackend-1.onrender.com/users/user`
      );
     setAllUserData(response.data);
      
      //Flatten transactionRequest arrays and sort with "Pending" first
      const allTransactions = await response.data
        .flatMap((user) => user.transactionRequest.map((t) => ({ ...t, username: user.username,  phone: user.mobileNumber, })))
        .sort((a, b) => {
          if (a.status === "Pending" && b.status !== "Pending") return -1;
          if (a.status !== "Pending" && b.status === "Pending") return 1;
          return new Date(b.requestTime) - new Date(a.requestTime); // Sort by date
        });
      setTransactions(allTransactions);
    } catch (err) {
      setError("Failed to fetch transactions. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions()
  }, []);

  // Approve transaction
  const handleApprove = async (transaction) => {
    try {
      
      const userData = allUserData.filter(
        (i) => i.username === transaction.username
      )[0];
      
      
      const oldTransactions = userData.transactionRequest.filter(
        (i) => i.requestTime !== transaction.requestTime
      );
      await axios.patch(
        `https://sratebackend-1.onrender.com/users/user/${userData.id}`,
        {
          wallet: +userData.wallet + +transaction.amount,
          transactionRequest: [
            ...oldTransactions,
            { ...transaction, status: "Approved" },
          ],
        }
      );
      fetchTransactions();
      alert("Transaction Approved!");
    } catch (err) {
      alert("Failed to approve transaction. Please try again.");
    }
  };

  // Reject transaction
  const handleReject = async (transaction) => {
    const userData = allUserData.filter(
      (i) => i.username === transaction.username
    )[0];
    const oldTransactions = userData.transactionRequest.filter(
      (i) => i.requestTime !== transaction.requestTime
    );
    const reason = prompt("Enter the reason for rejection:");
    if (!reason) {
      alert("Rejection reason is required.");
      return;
    }

    try {
      await axios.patch(
        `https://sratebackend-1.onrender.com/users/user/${userData.id}`,
        
        { 
          transactionRequest: [
            ...oldTransactions,
            { ...transaction, status: "Rejected", message: reason },
          ],
        }
      );
      fetchTransactions();
      alert("Transaction Rejected!");
    } catch (err) {
      alert("Failed to reject transaction. Please try again.");
    }
  };

  // Format date function
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  if (loading) {
    return <div>Loading transactions...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6">Transaction Verification</h1>
      {/* {JSON.stringify(filteredTransactions)} */}
      <div className="flex flex-wrap gap-4 mb-4">
  <input
    type="text"
    name="username"
    placeholder="Filter by Username"
    value={filters.username}
    onChange={(e) =>
      setFilters({ ...filters, username: e.target.value })
    }
    className="p-2 border border-gray-300 rounded"
  />
  <input
    type="text"
    name="phone"
    placeholder="Filter by Phone Number"
    value={filters.phone}
    onChange={(e) =>
      setFilters({ ...filters, phone: e.target.value })
    }
    className="p-2 border border-gray-300 rounded"
  />
</div>

      <div className="overflow-x-auto bg-white rounded-lg shadow-lg">
        <table className="min-w-full text-sm">
          <thead className="bg-gray-800 text-white sticky top-0">
            <tr>
              <th className="py-3 px-4 text-left">Username</th>
              <th className="py-3 px-4 text-left">Phone</th>
              

              <th className="py-3 px-4 text-left">Amount</th>
              <th className="py-3 px-4 text-left">Status</th>
              <th className="py-3 px-4 text-left">Request Date</th>
              <th className="py-3 px-4 text-left">UTR Number</th>
              <th className="py-3 px-4 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransactions.map((transaction, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-gray-50" : "bg-white"
                } hover:bg-gray-100`}
              >
                <td className="py-2 px-4 border">{transaction?.username}</td>
                <td className="py-2 px-4 border">{transaction?.phone || "N/A"}</td> {/* Add this */}
                <td className="py-2 px-4 border">₹{transaction?.amount}</td>
                <td className="py-2 px-4 border">
                  <span
                    className={`px-3 py-1 rounded-full text-sm font-medium ${
                      transaction?.status === "Approved"
                        ? "bg-green-200 text-green-800"
                        : transaction?.status === "Pending"
                        ? "bg-yellow-200 text-yellow-800"
                        : "bg-red-200 text-red-800"
                    }`}
                  >
                    {transaction?.status}
                  </span>
                </td>
                <td className="py-2 px-4 border">
                  {formatDate(transaction?.requestTime)}
                </td>
                <td className="py-2 px-4 border">{transaction?.utrNumber}</td>
                
                <td className="py-2 px-4 border flex gap-2">
                  {transaction?.status === "Pending" && (
                    <>
                      <button
                        className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                        onClick={() => handleApprove(transaction)}
                      >
                        Approve
                      </button>
                      <button
                        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                        onClick={() => handleReject(transaction)}
                      >
                        Reject
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TransactionVerification;
