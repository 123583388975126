import React, { useContext, useEffect, useState } from 'react';
import { MyData } from './Context';

const Dashboard = () => {
  const { allUserData, getAllUserFn } = useContext(MyData);
  const [stats, setStats] = useState({
    totalDeposits: 0,
    totalWithdrawals: 0,
    pendingWithdrawals: 0,
    totalTransactions: 0,
    recentTransactions: [],
    profitLoss: 0,
  });

  const [filters, setFilters] = useState({
    username: '',
    mobileNumber: '',
    type: 'All',
    date: '',
  });

  const [userFilters, setUserFilters] = useState({
    date: '',
  });

  useEffect(() => {
    getAllUserFn();
  }, []);

  useEffect(() => {
    if (allUserData.length > 0) {
      calculateStats();
    }
  }, [allUserData]);

  const calculateStats = () => {
    let totalDeposits = 0;
    let totalWithdrawals = 0;
    let pendingWithdrawals = 0;
    let pendingWithdrawalCount = 0;
    let pendingDeposits = 0;
    let pendingDepositCount = 0;
    let allTransactions = [];
    let totalLossBetAmount = 0;
    let totalWinnings = 0;
  
    const startOfDay = new Date();
    startOfDay.setHours(6, 0, 0, 0);
    const endOfDay = new Date();
    endOfDay.setHours(18, 59, 59, 999);
  
    allUserData.forEach((user) => {
      if (user.transactionRequest) {
        user.transactionRequest.forEach((trans) => {
          const transactionDate = new Date(trans.requestTime);
  
          if (
            trans.status === "Approved" &&
            transactionDate >= startOfDay &&
            transactionDate <= endOfDay
          ) {
            totalDeposits += parseFloat(trans.amount || 0);
          }
  
          if (trans.status === "Pending") {
            pendingDeposits += parseFloat(trans.amount || 0);
            pendingDepositCount++;
          }
  
          allTransactions.push({
            id: trans._id,
            user: user.username,
            mobileNumber: user.mobileNumber,
            amount: parseFloat(trans.amount || 0),
            type: "Deposit",
            status: trans.status,
            date: trans.requestTime,
            utrNumber: trans.utrNumber,
            message: trans.message || "N/A",
          });
        });
      }
  
      if (user.withdrawalRequest) {
        user.withdrawalRequest.forEach((withdraw) => {
          const withdrawalDate = new Date(withdraw.requestTime);
  
          if (
            withdraw.status === "Approved" &&
            withdrawalDate >= startOfDay &&
            withdrawalDate <= endOfDay
          ) {
            totalWithdrawals += parseFloat(withdraw.amount || 0);
          }
          if (withdraw.status === "Pending") {
            pendingWithdrawals += parseFloat(withdraw.amount || 0);
            pendingWithdrawalCount++;
          }
  
          allTransactions.push({
            id: withdraw._id,
            user: user.username,
            mobileNumber: user.mobileNumber,
            amount: parseFloat(withdraw.amount || 0),
            type: "Withdrawal",
            status: withdraw.status,
            date: withdraw.requestTime,
            accountDetails: withdraw.bankDetails,
          });
        });
      }
  
      if (user.betDetails) {
        user.betDetails.forEach((bet) => {
          const betDate = new Date(bet.betNo);
          if (betDate >= startOfDay && betDate <= endOfDay) {
            if (bet.status === "Won") {
              totalWinnings += parseFloat(
                (bet.betAmount || 0) * (bet.matkaBetType?.multiplier || 0)
              );
            } else if (bet.status === "Lost") {
              totalLossBetAmount += parseFloat(bet.betAmount || 0);
            }
          }
        });
      }
    });
  
    const profitLoss = totalWinnings - totalLossBetAmount;
  
    allTransactions.sort((a, b) => new Date(b.date) - new Date(a.date));
  
    setStats({
      totalDeposits,
      totalWithdrawals,
      pendingWithdrawals,
      pendingWithdrawalCount,
      pendingDeposits,
      pendingDepositCount,
      totalTransactions: allTransactions.length,
      recentTransactions: allTransactions,
      profitLoss: parseFloat(profitLoss.toFixed(2)),
    });
  };
  

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };
  

  const applyFilters = () => {
    let filteredTransactions = stats.recentTransactions;
  
    if (filters.username) {
      filteredTransactions = filteredTransactions.filter((transaction) =>
        transaction.user.toLowerCase().includes(filters.username.toLowerCase())
      );
    }
  
    if (filters.mobileNumber) {
      filteredTransactions = filteredTransactions.filter((transaction) =>
        transaction.mobileNumber.includes(filters.mobileNumber)
      );
    }
  
    if (filters.type !== "All") {
      filteredTransactions = filteredTransactions.filter(
        (transaction) => transaction.type === filters.type
      );
    }
  
    if (filters.date) {
      const selectedDate = new Date(filters.date);
      filteredTransactions = filteredTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.date);
        return (
          transactionDate.getFullYear() === selectedDate.getFullYear() &&
          transactionDate.getMonth() === selectedDate.getMonth() &&
          transactionDate.getDate() === selectedDate.getDate()
        );
      });
    }
  
    return filteredTransactions;
  };
  

  const filteredTransactions = applyFilters();

  const applyUserFilters = () => {
    let filteredUsers = [...allUserData];
    if (userFilters.date) {
      const selectedDate = new Date(userFilters.date);
      filteredUsers = filteredUsers.filter((user) => {
        const userJoinDate = new Date(user.createdAt);
        return (
          userJoinDate.getFullYear() === selectedDate.getFullYear() &&
          userJoinDate.getMonth() === selectedDate.getMonth() &&
          userJoinDate.getDate() === selectedDate.getDate()
        );
      });
    }
    return filteredUsers.reverse();
  };

  const filteredUsers = applyUserFilters();

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6">Dashboard</h1>
<div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
  <div className="bg-white rounded-lg p-6 shadow-lg">
    <h2 className="text-lg font-semibold text-gray-600">Total Users</h2>
    <p className="text-3xl font-bold text-blue-600">{allUserData?.length}</p>
  </div>
  <div className="bg-white rounded-lg p-6 shadow-lg">
    <h2 className="text-lg font-semibold text-gray-600">Total Deposits</h2>
    <p className="text-3xl font-bold text-green-600">₹{stats.totalDeposits}</p>
  </div>
  <div className="bg-white rounded-lg p-6 shadow-lg">
    <h2 className="text-lg font-semibold text-gray-600">Total Withdrawals</h2>
    <p className="text-3xl font-bold text-red-600">₹{stats.totalWithdrawals}</p>
  </div>
  <div className="bg-white rounded-lg p-6 shadow-lg">
    <h2 className="text-lg font-semibold text-gray-600">Pending Withdrawals</h2>
    <p className="text-3xl font-bold text-yellow-600">
      ({stats.pendingWithdrawalCount} requests)
    </p>
  </div>
  <div className="bg-white rounded-lg p-6 shadow-lg">
    <h2 className="text-lg font-semibold text-gray-600">Pending Deposits</h2>
    <p className="text-3xl font-bold text-yellow-600">
       ({stats.pendingDepositCount} requests)
    </p>
  </div>
  <div className="bg-white rounded-lg p-6 shadow-lg">
    <h2 className="text-lg font-semibold text-gray-600">Today's Profit/Loss</h2>
    <p
      className={`text-3xl font-bold ${
        stats.profitLoss >= 0 ? "text-green-600" : "text-red-600"
      }`}
    >
      ₹
      {stats.profitLoss >= 0
        ? stats.profitLoss.toLocaleString()
        : `(${Math.abs(stats.profitLoss).toLocaleString()})`}
    </p>
  </div>
</div>


      <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
        <h2 className="text-xl font-bold mb-4">All Transactions</h2>

        <div className="flex flex-wrap gap-4 mb-4">
  <input
    type="text"
    name="username"
    placeholder="Filter by Username"
    value={filters.username}
    onChange={handleFilterChange}
    className="p-2 border border-gray-300 rounded"
  />
  <input
    type="text"
    name="mobileNumber"
    placeholder="Filter by Mobile Number"
    value={filters.mobileNumber}
    onChange={handleFilterChange}
    className="p-2 border border-gray-300 rounded"
  />
  <select
    name="type"
    value={filters.type}
    onChange={handleFilterChange}
    className="p-2 border border-gray-300 rounded">
    <option value="All">All Types</option>
    <option value="Deposit">Deposit</option>
    <option value="Withdrawal">Withdrawal</option>
  </select>
  <input
    type="date"
    name="date"
    value={filters.date}
    onChange={handleFilterChange}
    className="p-2 border border-gray-300 rounded"
  />
</div>

        <div className="overflow-y-auto max-h-96">
          <table className="min-w-full">
            <thead className="bg-gray-100">
              <tr>
                <th className="py-3 px-4 text-left">User</th>
                <th className="py-3 px-4 text-left">Phone</th>
                <th className="py-3 px-4 text-left">Type</th>
                <th className="py-3 px-4 text-left">Amount</th>
                <th className="py-3 px-4 text-left">Status</th>
                <th className="py-3 px-4 text-left">Date</th>
                <th className="py-3 px-4 text-left">Message</th>
                <th className="py-3 px-4 text-left">Details</th>
              </tr>
            </thead>
            <tbody>
              {filteredTransactions.map((transaction, index) => (
                <tr key={index} className="border-b hover:bg-gray-50">
                  <td className="py-2 px-4">{transaction.user}</td>
                  <td className="py-2 px-4">{transaction.mobileNumber || "N/A"}</td> {/* Add this */}
              
                  <td className="py-2 px-4">
                    <span
                      className={`px-2 py-1 rounded-full text-xs ${
                        transaction.type === 'Deposit'
                          ? 'bg-green-100 text-green-800'
                          : 'bg-red-100 text-red-800'
                      }`}>
                      {transaction.type}
                    </span>
                  </td>
                  <td className="py-2 px-4">₹{transaction.amount}</td>
                  <td className="py-2 px-4">
                    <span
                      className={`px-2 py-1 rounded-full text-xs ${
                        transaction.status === 'Approved'
                          ? 'bg-green-100 text-green-800'
                          : transaction.status === 'Pending'
                          ? 'bg-yellow-100 text-yellow-800'
                          : 'bg-red-100 text-red-800'
                      }`}>
                      {transaction.status}
                    </span>
                  </td>

                  <td className="py-2 px-4">{new Date(transaction.date).toLocaleString()}</td>
                  <td className="py-2 px-4">{transaction.message || 'N/A'}</td>
                  <td className="py-2 px-4">
                    {transaction.type === 'Deposit'
                      ? `UTR: ${transaction.utrNumber || 'N/A'}`
                      : `A/C: ${
                          transaction.accountDetails?.accountNumber
                            ? 'XXXX' +
                              transaction.accountDetails.accountNumber.slice(-4)
                            : 'N/A'
                        }`}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-xl font-bold mb-4">Recent Users</h2>

        <div className="flex flex-wrap gap-4 mb-4">
          <input
            type="date"
            name="date"
            value={userFilters.date}
            onChange={(e) =>
              setUserFilters({
                ...userFilters,
                date: e.target.value,
              })
            }
            className="p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="overflow-y-auto max-h-96">
          <table className="min-w-full">
            <thead className="bg-gray-100">
              <tr>
                <th className="py-3 px-4 text-left">Username</th>
                <th className="py-3 px-4 text-left">Mobile</th>
                <th className="py-3 px-4 text-left">Wallet Balance</th>
                <th className="py-3 px-4 text-left">Bank Status</th>
                <th className="py-3 px-4 text-left">Joined Date</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user, index) => (
                <tr key={user._id} className="border-b hover:bg-gray-50">
                  <td className="py-2 px-4">{user.username}</td>
                  <td className="py-2 px-4">{user.mobileNumber}</td>
                  <td className="py-2 px-4">₹{user.wallet}</td>
                  <td className="py-2 px-4">
                    <span
                      className={`px-2 py-1 rounded-full text-xs ${
                        user.bankDetails?.isApproved
                          ? 'bg-green-100 text-green-800'
                          : user.bankDetails
                          ? 'bg-yellow-100 text-yellow-800'
                          : 'bg-gray-100 text-gray-800'
                      }`}>
                      {user.bankDetails?.isApproved
                        ? 'Approved'
                        : user.bankDetails
                        ? 'Pending'
                        : 'Not Added'}
                    </span>
                  </td>
                  <td className="py-2 px-4">
                    {new Date(user.createdAt).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
