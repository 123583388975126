import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Slabs() {
  const [slabForm, setSlabForm] = useState({
    marketId: '',
    slabName: '',
    rates: [
      { category: 'Single Digit', multiplier: 10 },
      { category: 'Jodi', multiplier: 100 },
      { category: 'Single Pana', multiplier: 160 },
      { category: 'Double Pana', multiplier: 320 },
      { category: 'Triple Pana', multiplier: 1000 },
      { category: 'Half Sangam ', multiplier: 1500 },
      { category: 'Full Sangam ', multiplier: 15000 },
    ]
  });

  const [slabs, setSlabs] = useState([]);

  // Fetch slabs on component load
  useEffect(() => {
    fetchSlabs();
  }, []);

  const fetchSlabs = async () => {
    try {
      const response = await axios.get('https://sratebackend-1.onrender.com/api/slabs');
      setSlabs(response.data.slabs);
    } catch (error) {
      console.error('Error fetching slabs:', error);
    }
  };

  const submitSlabRate = async () => {
    if (!slabForm.marketId || !slabForm.slabName) {
      alert('Please fill in all required fields.');
      return;
    }

    try {
      const response = await axios.post('https://sratebackend-1.onrender.com/api/slabs', slabForm);
      if (response.data.success) {
        alert('Slab rate created successfully!');
        fetchSlabs(); // Refresh the slabs list
        setSlabForm({
          marketId: '',
          slabName: '',
          rates: [
            { category: 'Single Digit', multiplier: 10 },
            { category: 'Jodi', multiplier: 100 },
            { category: 'Single Pana', multiplier: 160 },
            { category: 'Double Pana', multiplier: 320 },
            { category: 'Triple Pana', multiplier: 1000 },
            { category: 'Half Sangam ', multiplier: 1500 },
            { category: 'Full Sangam ', multiplier: 15000 },
          ]
        });
      } else {
        alert(`Failed to create slab rate: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error creating slab rate:', error);
      alert('An error occurred while creating the slab rate.');
    }
  };

  const deleteSlab = async (slabId) => {
    try {
      const response = await axios.delete(`https://sratebackend-1.onrender.com/api/slabs/${slabId}`);
      if (response.data.success) {
        alert('Slab deleted successfully!');
        fetchSlabs(); // Refresh the slabs list
      } else {
        alert(`Failed to delete slab: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error deleting slab:', error);
      alert('An error occurred while deleting the slab.');
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6">Slab Management</h1>

      {/* Slab Creation Form */}
      <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
        <h2 className="text-xl font-bold mb-4">Create New Slab Rate</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Market ID</label>
            <input
              type="text"
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              placeholder="Market ID (e.g., 1,2,3)"
              value={slabForm.marketId}
              onChange={(e) => setSlabForm({ ...slabForm, marketId: e.target.value })}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Slab Name</label>
            <input
              type="text"
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              placeholder="Slab Name"
              value={slabForm.slabName}
              onChange={(e) => setSlabForm({ ...slabForm, slabName: e.target.value })}
            />
          </div>
        </div>

        <div className="mt-4">
          <h3 className="text-lg font-semibold">Rates</h3>
          {slabForm.rates.map((rate, index) => (
            <div key={index} className="grid grid-cols-2 gap-4 mt-2">
              <input
                type="text"
                className="p-2 border border-gray-300 rounded"
                placeholder="Category"
                value={rate.category}
                onChange={(e) => {
                  const updatedRates = [...slabForm.rates];
                  updatedRates[index].category = e.target.value;
                  setSlabForm({ ...slabForm, rates: updatedRates });
                }}
              />
              <input
                type="number"
                className="p-2 border border-gray-300 rounded"
                placeholder="Multiplier"
                value={rate.multiplier}
                onChange={(e) => {
                  const updatedRates = [...slabForm.rates];
                  updatedRates[index].multiplier = Number(e.target.value);
                  setSlabForm({ ...slabForm, rates: updatedRates });
                }}
              />
            </div>
          ))}
        </div>

        <button
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded"
          onClick={submitSlabRate}
        >
          Create Slab Rate
        </button>
      </div>

      {/* List of Slabs */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-xl font-bold mb-4">All Slabs</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-100">
              <tr>
                <th className="py-3 px-4 text-left">Market ID</th>
                <th className="py-3 px-4 text-left">Slab Name</th>
                <th className="py-3 px-4 text-left">Rates</th>
                <th className="py-3 px-4 text-left">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {slabs.map((slab) => (
                <tr key={slab._id} className="hover:bg-gray-50">
                  <td className="py-2 px-4">{slab.marketId}</td>
                  <td className="py-2 px-4">{slab.slabName}</td>
                  <td className="py-2 px-4">
                    {slab.rates.map((rate, index) => (
                      <div key={index}>
                        {rate.category}: {rate.multiplier}
                      </div>
                    ))}
                  </td>
                  <td className="py-2 px-4">
                    <button
                      className="px-3 py-1 bg-red-600 text-white rounded"
                      onClick={() => deleteSlab(slab._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Slabs;
