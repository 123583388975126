import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { MyData } from "./Context";

const BankDetailsModal = ({ details, onClose }) => {
  if (!details) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-96 max-w-[90%]">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold">Bank Details</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>
        <div className="space-y-3">
          <div className="bg-gray-50 p-3 rounded">
            <p className="text-sm text-gray-500">PhonePay/Gpay/Paytm No.</p>
            <p className="font-medium">{details.upiphoneNumber}</p>
          </div>
          <div className="bg-gray-50 p-3 rounded">
            <p className="text-sm text-gray-500">UPI ID</p>
            <p className="font-medium">{details.upiId}</p>
          </div>
          <div className="bg-gray-50 p-3 rounded">
            <p className="text-sm text-gray-500">Account Number</p>
            <p className="font-medium">{details.accountNumber}</p>
          </div>
          <div className="bg-gray-50 p-3 rounded">
            <p className="text-sm text-gray-500">IFSC Code</p>
            <p className="font-medium">{details.ifscCode}</p>
          </div>
          <div className="bg-gray-50 p-3 rounded">
            <p className="text-sm text-gray-500">Account Holder</p>
            <p className="font-medium">{details.accountHolderName}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Withdrawal = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBankDetails, setSelectedBankDetails] = useState(null);
  const [showBankModal, setShowBankModal] = useState(false);
  const [filters, setFilters] = useState({
    username: "",
    mobile: "",
    status: "all",
  });

  const { allUserData, getAllUserFn } = useContext(MyData);

  const fetchTransactions = async () => {
    try {
      const response = await axios.get("https://sratebackend-1.onrender.com/users/user");
      const users = response.data;

      const allTransactions = users.flatMap((user) => {
        return (user.withdrawalRequest || []).map((request) => ({
          ...request,
          username: user.username,
          mobile: user.mobileNumber,
          bankDetails: user.bankDetails,
        }));
      });

      setTransactions(allTransactions.reverse());
    } catch (err) {
      setError("Failed to fetch transactions. Please try again later.");
      console.error("Fetch error:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
    getAllUserFn();
  }, []);

  const handleApprove = async (transaction) => {
    try {
      const userData = allUserData.find((i) => i.username === transaction.username);
      if (!userData) {
        alert("User not found!");
        return;
      }

      const reason = prompt("Enter the reason for approval:");

      const updatedTransactions = userData.withdrawalRequest.filter(
        (i) => i.requestTime !== transaction.requestTime
      );

      await axios.patch(`https://sratebackend-1.onrender.com/users/user/${userData.id}`, {
        withdrawalRequest: [
          ...updatedTransactions,
          {
            ...transaction,
            status: "Approved",
            approvedAt: new Date().toISOString(),
            message: reason,
          },
        ],
      });

      await fetchTransactions();
      alert(`Withdrawal of ₹${transaction.amount} approved successfully!`);
    } catch (err) {
      console.error("Approval error:", err);
      alert("Failed to approve withdrawal. Please try again.");
    }
  };

  const handleReject = async (transaction) => {
    try {
      const userData = allUserData.find((i) => i.username === transaction.username);
      if (!userData) {
        alert("User not found!");
        return;
      }

      const reason = prompt("Enter the reason for rejection:");
      if (!reason) {
        alert("Rejection reason is required.");
        return;
      }

      const updatedTransactions = userData.withdrawalRequest.filter(
        (i) => i.requestTime !== transaction.requestTime
      );

      const updatedWalletBalance =
        parseFloat(userData.wallet) + parseFloat(transaction.amount);

      await axios.patch(`https://sratebackend-1.onrender.com/users/user/${userData.id}`, {
        wallet: updatedWalletBalance,

        withdrawalRequest: [
          ...updatedTransactions,
          {
            ...transaction,
            status: "Rejected",
            message: reason,
            rejectedAt: new Date().toISOString(),
          },
        ],
      });

      await fetchTransactions();
      alert(`Withdrawal request rejected. ₹${transaction.amount} added back to the wallet.`);
    } catch (err) {
      console.error("Rejection error:", err);
      alert("Failed to reject withdrawal. Please try again.");
    }
  };

  const filteredTransactions = transactions
  .filter((transaction) => {
    const matchesUsername = transaction.username
      .toLowerCase()
      .includes(filters.username.toLowerCase());
    const matchesMobile = transaction.mobile
      .toLowerCase()
      .includes(filters.mobile.toLowerCase());
    const matchesStatus =
      filters.status === "all" || transaction.status === filters.status;

    return matchesUsername && matchesMobile && matchesStatus;
  })
  .sort((a, b) => {
    if (a.status === "Pending" && b.status !== "Pending") return -1;
    if (a.status !== "Pending" && b.status === "Pending") return 1;
    return new Date(b.requestTime) - new Date(a.requestTime); // Sort by date
  });


  if (loading) {
    return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="p-4 bg-red-100 text-red-700 rounded-lg">{error}</div>;
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Withdrawal Requests</h1>
        <div className="flex gap-2">
          <input
            type="text"
            placeholder="Filter by Username"
            value={filters.username}
            onChange={(e) => setFilters({ ...filters, username: e.target.value })}
            className="px-4 py-2 border rounded-lg"
          />
          <input
            type="text"
            placeholder="Filter by Mobile"
            value={filters.mobile}
            onChange={(e) => setFilters({ ...filters, mobile: e.target.value })}
            className="px-4 py-2 border rounded-lg"
          />
          <select
            className="px-4 py-2 border rounded-lg"
            value={filters.status}
            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
          >
            <option value="all">All Status</option>
            <option value="Pending">Pending</option>
            <option value="Approved">Approved</option>
            <option value="Rejected">Rejected</option>
          </select>
        </div>
      </div>

      {showBankModal && (
        <BankDetailsModal
          details={selectedBankDetails}
          onClose={() => setShowBankModal(false)}
        />
      )}

      <div className="overflow-x-auto bg-white rounded-lg shadow-lg">
        <table className="min-w-full text-sm">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="py-3 px-4 text-left">Username</th>
              <th className="py-3 px-4 text-left">Mobile</th>
              <th className="py-3 px-4 text-left">Account Details</th>
              <th className="py-3 px-4 text-left">Amount</th>
              <th className="py-3 px-4 text-left">Status</th>
              <th className="py-3 px-4 text-left">Request Date</th>
              <th className="py-3 px-4 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransactions.map((transaction, index) => (
              <tr key={index} className={`$ {index % 2 === 0 ? "bg-gray-50" : "bg-white"}`}> 
                <td className="py-2 px-4 border">{transaction.username}</td>
                <td className="py-2 px-4 border">{transaction.mobile}</td>
                <td className="py-2 px-4 border">
                  <button
                    onClick={() => {
                      setSelectedBankDetails(transaction.bankDetails);
                      setShowBankModal(true);
                    }}
                    className="px-2 py-1 bg-blue-100 text-blue-600 rounded hover:bg-blue-200"
                  >
                    View Details
                  </button>
                </td>
                <td className="py-2 px-4 border">₹{transaction.amount}</td>
                <td className="py-2 px-4 border">
                  <span
                    className={`px-3 py-1 rounded-full text-sm font-medium ${
                      transaction.status === "Approved"
                        ? "bg-green-200 text-green-800"
                        : transaction.status === "Pending"
                        ? "bg-yellow-200 text-yellow-800"
                        : "bg-red-200 text-red-800"
                    }`}
                  >
                    {transaction.status}
                  </span>
                </td>
                <td className="py-2 px-4 border">
                  {new Date(transaction.requestTime).toLocaleString()}
                </td>
                <td className="py-2 px-4 border">
                  {transaction.status === "Pending" && (
                    <div className="flex gap-2">
                      <button
                        onClick={() => handleApprove(transaction)}
                        className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                      >
                        Approve
                      </button>
                      <button
                        onClick={() => handleReject(transaction)}
                        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                      >
                        Reject
                      </button>
                    </div>
                  )}
                  {transaction.status === "Rejected"||transaction.status==="Approved" && <span>Reason: {transaction.message}</span>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Withdrawal;
