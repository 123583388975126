import React, { useContext, useEffect, useState } from "react";
import { MyData } from "./Context";
import axios from "axios";
import DepositToggle from "../components/DepositToggle";
import WithdrawalToggle from "../components/WithdrawalToggle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const UserManagement = () => {
  useEffect(() => {
    getAllUserFn();
  }, []);

  const { allUserData, getAllUserFn } = useContext(MyData);

  const [isCreditModalOpen, setIsCreditModal] = useState(false);
  const [isDebitModalOpen, setIsDebitModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState("");
  const [amount, setAmount] = useState("");
  const [slabs, setSlabs] = useState([]);
  const [filters, setFilters] = useState({
    username: "",
    phone: "",
  });
  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };
  
  
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
   
  };

  const filteredUsers = allUserData.map((user) => {
    // Initialize admin profit/loss
    let adminProfitLoss = 0;
  
    // Iterate over each bet
    user.betDetails?.forEach((bet) => {
      const betAmount = parseFloat(bet.betAmount) || 0; // Ensure numeric value
      const multiplier = parseFloat(bet.matkaBetType?.multiplier) || 1; // Default multiplier to 1
  
      if (bet.status === "Won") {
        // Calculate winnings and subtract from admin profit
        const winnings = betAmount * multiplier;
        adminProfitLoss -= winnings; // Admin loses the winnings amount
      } else if (bet.status === "Lost") {
        // Add bet amount to admin profit
        adminProfitLoss += betAmount;
      }
    });
  
    return {
      ...user,
      adminProfitLoss: adminProfitLoss || 0, // Ensure numeric value
    };
  }).filter(
    (user) =>
      user.username.toLowerCase().includes(filters.username.toLowerCase()) &&
      user.mobileNumber.includes(filters.phone)
  );
  

  useEffect(() => {
    fetchSlabs();
    getAllUserFn();
  }, []);

  const fetchSlabs = async () => {
    try {
      const response = await axios.get("https://sratebackend-1.onrender.com/api/slabs");
      setSlabs(response.data.slabs || []);
    } catch (error) {
      console.error("Error fetching slabs:", error);
    }
  };

  const handleAssignSlab = async (user, slabId) => {
    try {
      const selectedSlab = slabs.find((slab) => slab._id === slabId);
      console.log("Selected Slab:", selectedSlab); // Debugging
  
      if (!selectedSlab) {
        alert("Invalid slab selected.");
        return;
      }
  
      const response = await axios.patch(
        `https://sratebackend-1.onrender.com/users/user/${user.id}`,
        {
          assignedSlab: selectedSlab,
        }
      );
  
      console.log("Assign Slab Response:", response.data); // Debugging
  
      if (response.data.success) {
        alert("Slab assigned successfully!");
        getAllUserFn();
      } else {
        alert("Failed to assign slab. Server did not confirm success.");
      }
    } catch (error) {
      console.error("Error assigning slab:", error);
      alert("Failed to assign slab. Please try again.");
    }
  };
  

  const openCreditModal = (user) => {
    setSelectedUser(user);
    setIsCreditModal(true);
  };

  const openDebitModal = (user) => {
    setSelectedUser(user);
    setIsDebitModal(true);
  };

  const closeModal = () => {
    setIsCreditModal(false);
    setIsDebitModal(false);
    setSelectedUser(null);
    setAmount("");
  };

  const handleDeleteUser = async (user) => {
    const confirmation = window.confirm(
      `Are you sure you want to delete the user ${user.username}?`
    );

    if (!confirmation) return;

    try {
      await axios.delete(
        `https://sratebackend-1.onrender.com/users/user/${user.id}`
      );
      alert(`User ${user.username} deleted successfully!`);
      getAllUserFn(); // Refresh user data
    } catch (err) {
      console.error("Failed to delete user:", err);
      alert("Failed to delete user. Please try again.");
    }
  };

  const handleCredit = async (user) => {
    try {
      const oldTransactions = user.transactionRequest || [];
      await axios.patch(`https://sratebackend-1.onrender.com/users/user/${user.id}`, {

        wallet: +user.wallet + +amount,
        transactionRequest: [
          ...oldTransactions,
          {
            amount,
            status: "Approved",
            requestTime: Date.now(),
            utrNumber: "",
            username: user._id,
            message,
          },
        ],
      });
      alert("Credited Successfully");
      setIsCreditModal(false);
      getAllUserFn();
    } catch (err) {
      console.error("Failed to add Credit:", err);
      alert("Failed to add Credit. Please try again.");
    }
  };

  const handleDebit = async (user) => {
    try {
      const oldWithdrawals = user.withdrawalRequest || [];
      await axios.patch(`https://sratebackend-1.onrender.com/users/user/${user.id}`, {
        wallet: +user.wallet - +amount,
        withdrawalRequest: [
          ...oldWithdrawals,
          {
            amount,
            status: "Approved",
            requestTime: Date.now(),
            username: user._id,
            message,
          },
        ],
      });
      alert("Debited Successfully");
      setIsDebitModal(false);
      getAllUserFn();
    } catch (err) {
      console.error("Failed to Debit:", err);
      alert("Failed to Debit. Please try again.");
    }
  };

  const handleWithdraw = (user)=>{
    axios.patch(`https://sratebackend-1.onrender.com/users/user/${user.id}`, {
      isWithdrawal : !user?.isWithdrawal
    }).then((res)=>{ alert("User Updated");getAllUserFn()}, (err)=> alert("Error"))
  }
  const handleDeposit = (user)=>{
    axios.patch(`https://sratebackend-1.onrender.com/users/user/${user.id}`, {
      isDeposit : !user?.isDeposit
    }).then((res)=> {alert("User Updated"); getAllUserFn()}, (err)=> alert("Error"))
  }

  

  return (
    <div className="p-6 bg-gray-100 h-full">
      <h1 className="text-3xl font-bold mb-4">User Management</h1>
      <div className="flex gap-4 mb-4">
  <input
    type="text"
    name="username"
    placeholder="Search by Username"
    value={filters.username}
    onChange={handleFilterChange}
    className="p-2 border border-gray-300 rounded"
  />
  <input
    type="text"
    name="phone"
    placeholder="Search by Phone Number"
    value={filters.phone}
    onChange={handleFilterChange}
    className="p-2 border border-gray-300 rounded"
  />
</div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white rounded-lg shadow-lg">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-3 px-4 text-left">User ID</th>
              <th className="py-3 px-4 text-left">Username</th>
              <th className="py-3 px-4 text-left">Phone</th>
              <th className="py-3 px-4 text-left">Wallet Amount</th>
              <th className="py-3 px-4 text-left">Profit/Loss</th>
              <th className="py-3 px-4 text-left">Deposit</th>
              <th className="py-3 px-4 text-left">Withdrawal</th>
              <th className="py-3 px-4 text-left">Actions</th>
              {/* <th className="py-3 px-4 text-left">Assigned Slab</th> */}
              <th className="py-3 px-4 text-left">Delete</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr key={user.id} className="hover:bg-gray-100">
                <td className="py-2 px-4 border">{index + 1}</td>
                <td className="py-2 px-4 border">{user.username}
                <FontAwesomeIcon
    icon={faCopy}
    className="cursor-pointer text-gray-500 hover:text-gray-700 ml-2" // Added margin-left
    onClick={() => handleCopy(user.username)}
  />
                </td>
                <td className="py-2 px-4 border">{user.mobileNumber}
                <FontAwesomeIcon
    icon={faCopy}
    className="cursor-pointer text-gray-500 hover:text-gray-700 ml-2" // Added margin-left
    onClick={() => handleCopy(user.mobileNumber)}
  />
                </td>
                <td className="py-2 px-4 border">₹{user.wallet}</td>
                <td className={`py-2 px-4 border font-bold ${user.adminProfitLoss >= 0 ? 'text-green-600' : 'text-red-600'}`}>
  ₹{Number(user.adminProfitLoss).toFixed(2)}
</td>

                <td className="py-2 px-4 border">
                  <DepositToggle user={user} onToggle={()=>handleDeposit(user)}></DepositToggle>
                </td>
                <td className="py-2 px-4 border">
                  <WithdrawalToggle user={user} onToggle={()=>handleWithdraw(user)}></WithdrawalToggle>
                </td>
                <td className="py-2 px-4 border flex space-x-2">
                  <button
                    onClick={() => openCreditModal(user)}
                    className="bg-green-500 text-white p-1 rounded-md hover:bg-green-600 transition duration-300"
                  >
                    Credit
                  </button>
                  <button
                    onClick={() => openDebitModal(user)}
                    className="bg-blue-500 text-white p-1 rounded-md hover:bg-blue-600 transition duration-300"
                  >
                    Debit
                  </button>
                </td>
                {/* <td className="py-2 px-4 border">
                  <select
                    value={user.assignedSlab?._id || ""}
                    onChange={(e) => handleAssignSlab(user, e.target.value)}
                    className="p-2 border border-gray-300 rounded"
                  >
                    <option value="">Select Slab</option>
                    {slabs.map((slab) => (
                      <option key={slab._id} value={slab._id}>
                        {slab.slabName}
                      </option>
                    ))}
                  </select>
                </td> */}
                <td className="py-2 px-4 border">
                  <button
                    onClick={() => handleDeleteUser(user)}
                    className="bg-red-500 text-white p-1 rounded-md hover:bg-red-600 transition duration-300"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Credit Modal */}
      {isCreditModalOpen && selectedUser && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 max-w-md mx-auto">
            <h2 className="text-xl font-bold mb-4">
              Add Credit for {selectedUser.username}
            </h2>
            <div className="space-y-2">
              <label className="block text-sm font-medium">Amount:</label>
              <input
                type="number"
                placeholder="Enter Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="border p-2 w-full rounded-md"
              />
              <label className="block text-sm font-medium">Reason:</label>
              <input
                type="text"
                placeholder="Enter Reason"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="border p-2 w-full rounded-md"
              />
            </div>
            <div className="mt-4 text-right">
              <button
                onClick={closeModal}
                className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600"
              >
                Cancel
              </button>
              <button
                onClick={() => handleCredit(selectedUser)}
                className="bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600"
              >
                Add Credit
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Debit Modal */}
      {isDebitModalOpen && selectedUser && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 max-w-md mx-auto">
            <h2 className="text-xl font-bold mb-4">
              Debit Amount for {selectedUser.username}
            </h2>
            <div className="space-y-2">
              <label className="block text-sm font-medium">Amount:</label>
              <input
                type="number"
                placeholder="Enter Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="border p-2 w-full rounded-md"
              />
              <label className="block text-sm font-medium">Reason:</label>
              <input
                type="text"
                placeholder="Enter Reason"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="border p-2 w-full rounded-md"
              />
            </div>
            <div className="mt-4 text-right">
              <button
                onClick={closeModal}
                className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600"
              >
                Cancel
              </button>
              <button
                onClick={() => handleDebit(selectedUser)}
                className="bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600"
              >
                Debit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManagement;
